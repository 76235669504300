import React, { createContext, ReactNode, useState } from 'react';
import { useAuthClaims } from '../hooks/useAuthClaims';
import * as Sentry from '@sentry/react';
import { useMemoizedCacheTag } from '../hooks/useMemoizedCacheTag';
import { useLicensedProductsQuery, useSchoolYearsQuery } from '../client/bp-graphql-client-defs';
import { LicensedProductNextcloud } from '../pages/Institution/subpages/InstitutionOverviewSubpage';
import { type LicensedProduct, type SchoolYear } from '@bp/bp-graphql-types';

type ContextOrganizationType = {
  uuid: string;
  __typename?: string;
  name?: string;
};

type SchoolYearType = Pick<SchoolYear, 'uuid' | 'name' | 'start' | 'end'>;

export type OrganizationConfigType = {
  organization: ContextOrganizationType | null;
  setOrganization: (organization: ContextOrganizationType | null) => void;
  currentSchoolYear: SchoolYearType;
  setCurrentSchoolYear: (value: SchoolYearType | ((prevVar: SchoolYearType) => SchoolYearType)) => void;
  schoolyears: SchoolYearType[] | undefined;
  allowStudentDirectMessages: boolean;
  setAllowStudentDirectMessages: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  bildungsplattformLicense: boolean;
  setBildungsplattformLicense: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  bigBlueButtonLicense: boolean;
  setBigBlueButtonLicense: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  allowRecordings: boolean;
  setAllowRecordings: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  threemaLicense: boolean;
  setThreemaLicense: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  threemaBroadcastLicense: boolean;
  setThreemaBroadcastLicense: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  cloudLicense: boolean;
  setCloudLicense: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  cloudLink: string;
  setCloudLink: (value: string | ((prevVar: string) => string)) => void;
  zoomLicense: boolean;
  setZoomLicense: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  mailboxLicense: boolean;
  setMailboxLicense: (value: boolean | ((prevVar: boolean) => boolean)) => void;
  organizationProducts: Omit<LicensedProduct, 'organization' | 'organizationConnection'>[] | undefined;
};

export const OrganizationConfigContext = createContext<OrganizationConfigType>({
  organization: null,
  setOrganization: () => {
    return;
  },
  organizationProducts: [],
  currentSchoolYear: { uuid: '', name: '', start: '', end: '' },
  setCurrentSchoolYear() {
    return;
  },
  schoolyears: [],

  allowStudentDirectMessages: false,
  setAllowStudentDirectMessages() {
    return;
  },
  bildungsplattformLicense: false,
  setBildungsplattformLicense() {
    return;
  },
  bigBlueButtonLicense: false,
  setBigBlueButtonLicense() {
    return;
  },
  allowRecordings: false,
  setAllowRecordings() {
    return;
  },
  cloudLicense: false,
  setCloudLicense() {
    return;
  },
  cloudLink: '',
  setCloudLink() {
    return;
  },
  mailboxLicense: false,
  setMailboxLicense() {
    return;
  },
  threemaBroadcastLicense: false,
  setThreemaBroadcastLicense() {
    return;
  },
  threemaLicense: false,
  setThreemaLicense() {
    return;
  },
  zoomLicense: false,
  setZoomLicense() {
    return;
  },
});

export const OrganizationConfigContextProvider = ({ children }: { children: ReactNode }) => {
  const { pimAuthClaims } = useAuthClaims();

  const [organization, setOrganization] = useState<ContextOrganizationType | null>(pimAuthClaims.getOrganization());

  const [{ data: schoolyearsData }] = useSchoolYearsQuery({
    variables: {
      where: {
        organization: {
          uuid: pimAuthClaims.getOrganizationUuid(),
        },
      },
    },
    context: useMemoizedCacheTag('SCHOOL_YEAR'),
  });

  const [productsQueryResult] = useLicensedProductsQuery({
    variables: {
      where: {
        uuid: pimAuthClaims.getOrganizationUuid(),
      },
    },
    context: useMemoizedCacheTag('PRODUCTS'),
  });

  const organizationProducts = productsQueryResult.data?.organizations[0]?.licensedProducts;

  const [currentSchoolYear, setCurrentSchoolYear] = useState<SchoolYearType>(() => {
    return (
      schoolyearsData?.schoolYears.find(
        (year) =>
          year.uuid ===
          productsQueryResult.data?.organizations[0]?.licensedProducts.find((p) => p.__typename === 'Bildungsplattform')
            ?.currentSchoolyear,
      ) ?? { uuid: '', name: '', start: '', end: '' }
    );
  });

  const [allowStudentDirectMessages, setAllowStudentDirectMessages] = useState<boolean>(
    (organizationProducts?.filter((p) => p.__typename === 'Bildungsplattform' && p.allowStudentDirectMessages) ?? [])
      .length > 0,
  );

  const [bildungsplattformLicense, setBildungsplattformLicense] = useState(
    (organizationProducts?.filter((p) => p.__typename === 'Bildungsplattform') ?? []).length > 0,
  );
  const [bigBlueButtonLicense, setBigBlueButtonLicense] = useState(
    (organizationProducts?.filter((p) => p.__typename === 'BigBlueButton') ?? []).length > 0,
  );
  const [allowRecordings, setAllowRecordings] = useState(
    (organizationProducts?.filter((p) => p.__typename === 'BigBlueButton') ?? []).shift()?.allowRecording ?? false,
  );
  const [threemaLicense, setThreemaLicense] = useState(
    (organizationProducts?.filter((p) => p.__typename === 'Threema') ?? []).length > 0,
  );
  const [threemaBroadcastLicense, setThreemaBroadcastLicense] = useState(
    (organizationProducts?.filter((p) => p.__typename === 'ThreemaBroadcast') ?? []).length > 0,
  );
  const [cloudLicense, setCloudLicense] = useState(
    (organizationProducts?.filter((p) => p.__typename === 'Nextcloud') ?? []).length > 0,
  );
  const [cloudLink, setCloudLink] = useState<string>(
    (organizationProducts?.find((p) => p.__typename === 'Nextcloud') as LicensedProductNextcloud)?.serverUri ?? '',
  );
  const [zoomLicense, setZoomLicense] = useState(
    (organizationProducts?.filter((p) => p.__typename === 'Zoom') ?? []).length > 0,
  );
  const [mailboxLicense, setMailboxLicense] = useState(false);

  if (import.meta.env.VITE_APP_SENTRY_DSN) {
    Sentry.setUser({
      id: pimAuthClaims.getUser()?.uuid ?? '00000000-0000-0000-0000-000000000000',
      email: pimAuthClaims.getProfile()?.email ?? '',
      segment: organization?.name ?? '',
    });
  }

  return (
    <OrganizationConfigContext.Provider
      value={{
        organization,
        organizationProducts,
        setOrganization,
        currentSchoolYear,
        setCurrentSchoolYear,
        schoolyears: schoolyearsData?.schoolYears ?? [],
        allowStudentDirectMessages,
        setAllowStudentDirectMessages,
        bildungsplattformLicense,
        setBildungsplattformLicense,
        bigBlueButtonLicense,
        setBigBlueButtonLicense,
        allowRecordings,
        setAllowRecordings,
        threemaLicense,
        setThreemaLicense,
        threemaBroadcastLicense,
        setThreemaBroadcastLicense,
        cloudLicense,
        setCloudLicense,
        cloudLink,
        setCloudLink,
        mailboxLicense,
        setMailboxLicense,
        zoomLicense,
        setZoomLicense,
      }}
    >
      {children}
    </OrganizationConfigContext.Provider>
  );
};
