import { MsgType, NotificationCountType, RoomEvent } from 'matrix-js-sdk';
import { useMatrixClient } from './useMatrixClient';
import { useGetMatrixRoomByName } from './useGetMatrixRoomByName';
import { NotificationReasons, RoomNames } from '../../utils/matrixClient';
import { backendApi } from '../../utils/backendApi';
import { MatrixEvent } from 'matrix-js-sdk/lib/models/event';

export const useActivityProtocol = () => {
  const client = useMatrixClient();
  const { getRoom } = useGetMatrixRoomByName();

  const sendMessage = async (
    submissionUuid: string,
    type: NotificationReasons,
    message?: string | null,
    clicked = false,
  ) => {
    const room = getRoom(submissionUuid, RoomNames.Submission);
    if (room && client) {
      if (type !== NotificationReasons.Comment && type !== NotificationReasons.Answer) {
        // will find all data from the room name when displaying Notifications later
        return client.sendMessage(room.roomId, {
          body: JSON.stringify({ type, clicked: clicked }),
          formatted_body: message ?? undefined,
          msgtype: MsgType.Text,
        });
      } else if (message) {
        return client.sendMessage(room.roomId, {
          body: message,
          formatted_body: message,
          msgtype: MsgType.Text,
        });
      }
    }
    return null;
  };

  const timelineListener = (submissionUuid: string, callback: (event: MatrixEvent) => void) => {
    const room = getRoom(submissionUuid, RoomNames.Submission);
    if (room && client) {
      room.on(RoomEvent.Timeline, (event) => callback(event));
    }
  };

  const redactionListener = (submissionUuid: string, callback: (event: MatrixEvent) => void) => {
    const room = getRoom(submissionUuid, RoomNames.Submission);
    if (room && client) {
      room.on(RoomEvent.Redaction, (event) => callback(event));
    }
  };

  const unreadNotificationListener = (
    submissionUuid: string,
    callback: (number: Partial<Record<NotificationCountType, number>> | undefined) => void,
  ) => {
    const room = getRoom(submissionUuid, RoomNames.Submission);
    if (room && client) {
      room.on(RoomEvent.UnreadNotifications, (number) => callback(number));
    }
  };

  return {
    sendMessage,
    timelineListener,
    redactionListener,
    unreadNotificationListener,
    redactMessage: backendApi.redactMatrixEvent,
  };
};
