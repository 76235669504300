import { useAuthClaims } from '../../useAuthClaims';
import {
  useBpCalendarEventsQuery,
  useBpCollaboratingOrganizationsQuery,
  useBpSubmissionsQuery,
  useGroupAsGroupsQuery,
} from '../../../client/bp-graphql-client-defs';
import { isNotificationType, NotificationReasons, NotificationType } from '../../../utils/matrixClient';
import { useEffect, useState } from 'react';
import { mapNotification } from '../../../components/Header/components/Notifications/mapNotification';
import { useMemoizedCacheTag } from '../../useMemoizedCacheTag';
import { useMatrixAvailable } from '../useMatrixAvailable';
import { useRefreshOnEvent } from '../useRefreshOnEvent';
import { useMatrixClient } from '../useMatrixClient';
import { MatrixEvent } from 'matrix-js-sdk';
import { BaseDataType, useNotificationBaseData } from './useNotificationBaseData';

export enum BpRoomType {
  DM = 'dm',
  GROUP = 'group',
  COURSE = 'course',
  ORGANIZATION = 'organization',
  SUBMISSION = 'submission',
}

export const useNotifications = () => {
  const { pimAuthClaims } = useAuthClaims();
  const online = useMatrixAvailable();
  const refresh = useRefreshOnEvent();

  const matrixClient = useMatrixClient();

  const rooms = matrixClient?.getVisibleRooms().filter((room) => {
    const parts = room?.name.split(':')[0].split('_');
    const roomType: BpRoomType = parts[0] as BpRoomType;
    return roomType !== BpRoomType.DM;
  });

  const [notifications, setNotifications] = useState<NotificationType[]>([]);

  const profileUuid = pimAuthClaims.getProfile().uuid;
  const organizationUuid = pimAuthClaims.getOrganizationUuid();
  const otherProfilesUuids = pimAuthClaims.getOtherProfiles().map((p) => p.uuid);

  const groupContext = useMemoizedCacheTag('GROUP');
  const submissionContext = useMemoizedCacheTag('SUBMISSION');

  const [{ data: profileSubmissions }, refetchSubmissions] = useBpSubmissionsQuery({
    variables: {
      where: {
        ownerConnection: {
          node: {
            uuid_IN: [profileUuid, ...otherProfilesUuids],
          },
        },
      },
    },
    context: submissionContext,
  });

  const [{ data: groupsData }] = useGroupAsGroupsQuery({
    variables: {
      where: {
        OR: [
          { editors_SOME: { uuid_IN: [profileUuid, ...otherProfilesUuids] } },
          { viewers_SOME: { uuid_IN: [profileUuid, ...otherProfilesUuids] } },
        ],
      },
    },
    context: groupContext,
  });
  const [{ data: calendarEventsData }] = useBpCalendarEventsQuery({
    variables: {
      where: {
        attendees_SOME: { uuid: profileUuid },
      },
    },
    context: groupContext,
  });

  const [{ data: organizationsData }] = useBpCollaboratingOrganizationsQuery({
    variables: { uuid: pimAuthClaims.getOrganizationUuid() },
  });

  const { createBaseData } = useNotificationBaseData();

  useEffect(() => {
    const notifications: NotificationType[] = [];

    const latestJoinEventByRoom: Record<string, number> = {};

    const _updateLatestJoinEventTS = (matrixEvent: MatrixEvent) => {
      const isJoinEvent = matrixEvent.getContent().membership === 'join';
      const eventTs = matrixEvent.getTs();
      const roomId = matrixEvent.getRoomId();

      if (isJoinEvent && roomId) {
        if (roomId in latestJoinEventByRoom) {
          // we have apparently already seen a 'join' event for this user in this room; take the latest
          const oldEventTs = latestJoinEventByRoom[roomId];

          if (eventTs > oldEventTs) {
            latestJoinEventByRoom[roomId] = eventTs;
          }
        } else {
          latestJoinEventByRoom[roomId] = eventTs;
        }
      }
    };

    function _handleEventCreated(
      baseData: BaseDataType | undefined,
      payload: NotificationType,
      groupUuid: string,
      roomType: BpRoomType,
    ) {
      const _getGroupName = (uuid: string) => {
        return groupsData?.groups.find((g) => g.uuid === uuid)?.name ?? 'No Name';
      };

      if (baseData?.groupType === BpRoomType.GROUP) {
        if (payload.type === NotificationReasons.RecurringEventCreated) {
          payload.type = NotificationReasons.RecurringGroupEventCreated;
        } else {
          payload.type = NotificationReasons.GroupEventCreated;
        }
        payload.title = _getGroupName(groupUuid);
      }

      if (baseData?.groupType === BpRoomType.COURSE) {
        if (payload.type === NotificationReasons.RecurringEventCreated) {
          payload.type = NotificationReasons.RecurringCourseEventCreated;
        } else {
          payload.type = NotificationReasons.CourseEventCreated;
        }

        payload.title = _getGroupName(groupUuid);
      }

      if (roomType === BpRoomType.ORGANIZATION) {
        payload.type = NotificationReasons.OrganizationEventCreated;
        payload.title = baseData?.subjectName;
      }

      return payload;
    }

    function _handleAnnouncement(baseData: BaseDataType | undefined, payload: NotificationType, roomType: BpRoomType) {
      if (baseData?.groupType === BpRoomType.GROUP) {
        payload.type = NotificationReasons.NewGroupAnnouncement;
      }

      if (baseData?.groupType === BpRoomType.COURSE) {
        payload.type = NotificationReasons.NewCourseAnnouncement;
      }

      if (roomType === BpRoomType.ORGANIZATION) {
        payload.type = NotificationReasons.NewOrganizationAnnouncement;
      }

      return payload;
    }

    function dontShow(matrixEvent: MatrixEvent, roomId: string, type: string) {
      return (
        latestJoinEventByRoom[roomId] > matrixEvent.getTs() &&
        type !== NotificationReasons.Announcement &&
        type !== NotificationReasons.NewGroupMaterial &&
        type !== NotificationReasons.NewCourseMaterial
      );
    }

    rooms?.forEach((room) => {
      const parts = room?.name.split(':')[0].split('_');
      const roomType: BpRoomType = parts[0] as BpRoomType;
      const groupUuid: string | undefined = parts[2];
      const typeUuid = parts[1];
      const events = room?.getLiveTimeline().getEvents();

      const baseData = createBaseData(roomType, typeUuid, groupUuid);

      for (const matrixEvent of events) {
        _updateLatestJoinEventTS(matrixEvent);

        const eventBody = matrixEvent.getContent().body;
        if (!eventBody) continue;

        const roomId = matrixEvent.getRoomId();

        let payload: NotificationType | null = null;
        try {
          payload = JSON.parse(eventBody);
        } catch {
          // probably is a text message
          // the only place we use this are submissions
          if (
            eventBody &&
            // a teacher has no submissions, and only he / her should see this message
            !profileSubmissions?.submissions.find((s) => s.owner.uuid === pimAuthClaims.getProfile().uuid)
          ) {
            refetchSubmissions({ where: { uuid: groupUuid } });

            payload = {
              type: NotificationReasons.SubmissionMessage,
              date: matrixEvent.getDate() ?? new Date(),
              eventId: matrixEvent.getId(),
              roomId,
              content: eventBody,
            };
          }
        }

        if (isNotificationType(payload)) {
          // can be removed when typing ist consistent
          const type = payload.type?.charAt(0).toLowerCase() + payload.type?.slice(1);

          if (roomId && dontShow(matrixEvent, roomId, type)) continue;

          const data: NotificationType = {
            date: matrixEvent.getDate() ?? new Date(),
            type: payload.type,
            eventId: matrixEvent.getId(),
            roomId: matrixEvent.getRoomId(),
          };

          if (type === NotificationReasons.Announcement) {
            payload = { ...payload, ..._handleAnnouncement(baseData, payload, roomType) };
          }

          if (type === NotificationReasons.EventCreated) {
            payload = { ...payload, ..._handleEventCreated(baseData, payload, groupUuid, roomType) };
          }

          // technically courses are groups of type course
          if (type === NotificationReasons.NewGroupMembership && baseData?.groupType === BpRoomType.COURSE) {
            payload.type = NotificationReasons.NewCourseMembership;
          }

          if (
            type === NotificationReasons.EventStarted ||
            type === NotificationReasons.EventEnded ||
            type === NotificationReasons.EventJoined
          ) {
            // TODO: handle organization- and 1v1-meetings
            const event = calendarEventsData?.calendarEvents.find(
              (evt) => evt.uuid === (payload as NotificationType).subjectUuid,
            );
            payload.groupUuid =
              event?.holderConnection.edges
                ?.filter((edge) => edge.node.__typename === 'Group')
                .map((edge) => edge.node.uuid)
                .shift() ?? '';
          }

          const { content, targetUrl } = mapNotification({ ...baseData, ...payload, organizationUuid });
          if (content === '') continue;

          if (content) {
            data.content = content;
            data.targetUrl = targetUrl;
            notifications.push(data);
          }
        }
      }
    });
    const sorted = notifications.sort((a, b) => b.date.getTime() - a.date.getTime());
    setNotifications(sorted);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    online,
    refresh,
    organizationUuid,
    groupsData?.groups,
    profileSubmissions?.submissions,
    organizationsData?.collaboratingOrganizations,
  ]);

  return notifications;
};
