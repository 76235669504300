import { useEffect, useState } from 'react';
import { RoomEvent } from 'matrix-js-sdk';
import { useMatrixClient } from './useMatrixClient';

export const useRefreshOnEvent = () => {
  const [rerender, setRerender] = useState<Date>();
  const matrixClient = useMatrixClient();
  useEffect(() => {
    const handleTimeline = () => {
      setRerender(new Date());
    };
    matrixClient?.on(RoomEvent.Timeline, handleTimeline);
    matrixClient?.on(RoomEvent.Redaction, handleTimeline);

    return () => {
      matrixClient?.off(RoomEvent.Timeline, handleTimeline);
      matrixClient?.off(RoomEvent.Redaction, handleTimeline);
    };
  }, [matrixClient]);
  return rerender;
};
