import { useEffect, useState } from 'react';
import { Room } from 'matrix-js-sdk';
import { useMatrixClient } from './useMatrixClient';
import { useMatrixAvailable } from './useMatrixAvailable';

export const useGetMatrixRoom = (id: string) => {
  const [room, setRoom] = useState<Room | null | undefined>(null);
  const matrixClient = useMatrixClient();
  const online = useMatrixAvailable();

  useEffect(() => {
    const room = matrixClient?.getRoom(id);
    setRoom(room);
  }, [id, matrixClient, online]);
  return room;
};
