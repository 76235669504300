import * as matrixSDK from 'matrix-js-sdk';
import { MatrixClient, MatrixEvent } from 'matrix-js-sdk';
import { backendApi } from './backendApi';
import { isPlainOldObject } from './type-guards';
import { sleep } from 'matrix-js-sdk/lib/utils';
import dayjs from 'dayjs';

export enum RoomNames {
  Unknown = '',
  Submission = 'submission',
  Direct = 'dm',
  Organization = 'organization',
  Notification = 'user_notifications',
  Group = 'group',
}

export type ConversationType = {
  initials: string;
  isIncoming: boolean;
  color: string;
  id: string;
  messages: {
    type: string;
    content: string;
    date: Date;
    matrixEvent?: MatrixEvent;
  }[];
}[];

export enum NotificationReasons {
  SubmissionFeedback = 'submissionFeedback',
  SubmissionMessage = 'submissionMessage',
  FeedbackRejected = 'feedbackRejected',
  RequestToFeedback = 'requestToFeedback',
  Comment = 'comment',
  Answer = 'answer',
  RequestToPublish = 'requestToPublish',
  SubmissionPublished = 'submissionPublished',
  SubmissionDePublished = 'submissionDePublished',
  NewGroupMembership = 'newGroupMembership',
  NewCourseMembership = 'newCourseMembership',

  RemovedFromCourse = 'removedFromCourse',
  RemovedFromGroup = 'removedFromGroup',

  NewGroupAnnouncement = 'newGroupAnnouncement',
  NewCourseAnnouncement = 'newCourseAnnouncement',
  NewOrganizationAnnouncement = 'newOrganizationAnnouncement',
  NewGroupMaterial = 'newGroupMaterial',
  NewCourseMaterial = 'newCourseMaterial',
  CourseMaterialUpdated = 'courseMaterialUpdated',
  GroupMaterialUpdated = 'groupMaterialUpdated',

  AssignmentOpened = 'assignmentOpened',
  AssignmentUpdated = 'assignmentUpdated',
  AssignmentDeleted = 'assignmentDeleted',
  AssignmentCreated = 'assignmentCreated',

  SubmissionNew = 'submissionNew',
  SubmissionUpdated = 'submissionUpdated',

  MaterialDeleted = 'materialDeleted',
  NewMessage = 'newMessage',
  Announcement = 'announcement',

  EventCreated = 'eventCreated',
  OrganizationEventCreated = 'organizationEventCreated',
  CourseEventCreated = 'courseEventCreated',
  GroupEventCreated = 'groupEventCreated',
  RecurringEventCreated = 'recurringEventCreated',
  RecurringGroupEventCreated = 'recurringGroupEventCreated',
  RecurringCourseEventCreated = 'recurringCourseEventCreated',

  EventStarted = 'eventStarted',
  EventEnded = 'eventEnded',
  EventJoined = 'eventJoined',
}

export type NotificationType = {
  type: NotificationReasons;
  eventId: string | undefined;
  roomId: string | undefined;
  content?: string;
  title?: string;
  date: Date;
  clicked?: boolean;
  targetUrl?: string;
  submissionUuid?: string;
  subjectUuid?: string;
  subjectName?: string;
  groupUuid?: string;
  groupType?: 'course' | 'group';
  groupName?: string;
  assignmentUuid?: string;
  isCommunity?: boolean;
  organizationUuid?: string;
};

export const isNotificationType = (x: unknown): x is NotificationType => {
  return (
    isPlainOldObject(x) &&
    'type' in x &&
    typeof x.type === 'string' &&
    (x.eventId === undefined || typeof x.eventId === 'string') &&
    (x.roomId === undefined || typeof x.roomId === 'string') &&
    (x.content === undefined || x.content === null || typeof x.content === 'string') &&
    (x.title === undefined || x.title === null || typeof x.title === 'string') &&
    (x.clicked === undefined || x.clicked === null || typeof x.clicked === 'boolean') &&
    (x.groupUuid === undefined || x.groupUuid === null || typeof x.groupUuid === 'string') &&
    (x.subjectName === undefined || x.subjectName === null || typeof x.subjectName === 'string') &&
    (x.targetUrl === undefined || x.targetUrl === null || typeof x.targetUrl === 'string')
  );
};

// IStartClientOpts interface ist kaputt ... :-(
const startClientOptions = {
  resolveInvitesToProfiles: true,
  initialSyncLimit: 100,
  threadSupport: false,
};

export class BpMatrixClient {
  private static _client: null | MatrixClient = null;
  private static _inAction = false;

  public static async instance(): Promise<MatrixClient | null> {
    const start = async (enforceToken = false): Promise<boolean> => {
      let token = await backendApi.getMatrixToken(enforceToken);
      // expires_at is in milliseconds
      if (!token || token.expires_at < dayjs().unix() * 1000) {
        token = await backendApi.getMatrixToken(true);
      }

      if (token) {
        try {
          this._client = matrixSDK.createClient({
            baseUrl: import.meta.env.VITE_APP_HOMESERVER_URL ?? '',
            timelineSupport: true,
            accessToken: token.access_token,
            userId: token.user_id,
          });

          // returns an error when token invalid
          const whoami = await this._client.whoami();

          if ('errorcode' in whoami && whoami.errorcode === 'M_UNKNOWN_TOKEN') {
            this._client.stopClient();
            // recursive call, this time enforce a new token
            return false;
          } else {
            await this._client.startClient(startClientOptions);
          }

          this._inAction = false;
        } catch (e: unknown) {
          this._inAction = false;
          if (this._client) {
            this._client?.stopClient();
          }
          console.log('Error starting matrix client', e);
          return false;
        }

        return true;
      }

      this._client?.stopClient();

      return false;
    };

    if (!this._client && !this._inAction) {
      this._inAction = true;

      let started = false;
      while (!started) {
        started = await start();
        await sleep(100);
      }
    } else if (!this._client?.clientRunning && !this._inAction) {
      await start(true);
    }

    return this._client;
  }
}
