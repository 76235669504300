import { useMatrixClient } from './useMatrixClient';
import { Room } from 'matrix-js-sdk';
import { useState } from 'react';

export const useMarkAsRead = () => {
  const matrixClient = useMatrixClient();
  const [refresh, setRefresh] = useState<Date | null>();
  const markAsRead = async (room?: Room | null) => {
    if (room) {
      const timeline = room.getLiveTimeline();
      const events = timeline.getEvents();
      if (events.length) {
        const lastEvent = events[events.length - 1];
        if (lastEvent) {
          await matrixClient?.setRoomReadMarkers(room.roomId, lastEvent.getId() ?? '');
          await matrixClient?.sendReadReceipt(lastEvent);
          room?.setTimelineNeedsRefresh(true);
          setRefresh(new Date());
        }
      }
    }
  };

  return { markAsRead, refresh };
};
