import { backendApi } from './backendApi';

export const getMatrixRoomIdWithServerName = async (id: string) => {
  const token = await backendApi.getMatrixToken();
  if (id === '') {
    return id;
  }
  if (token && token.home_server) {
    return `${id}:${token.home_server}`;
  }
};

export const getMatrixRoomId = async (matrixId: string) => {
  const token = await backendApi.getMatrixToken();

  if (token && token.home_server && matrixId.includes(`:${token.home_server}`)) {
    return matrixId.split(`:${token.home_server}`)[0];
  }
  return matrixId;
};

export const getFullyQualifiedRoomId = async (matrixId: string) => {
  const token = await backendApi.getMatrixToken();

  if (token && token.home_server) {
    return `${matrixId}:${token.home_server}`;
  }
  return null;
};

export const getFullyQualifiedUser = (profileUuid: string, suffix: string) => {
  if (profileUuid.includes('@')) {
    return profileUuid;
  }
  return `@${profileUuid}:${suffix}`;
};

export const getUuidFromFullyQualifiedUser = (fullyQualifiedUser: string, suffix: string) => {
  if (!fullyQualifiedUser.includes('@')) {
    return fullyQualifiedUser;
  }
  return fullyQualifiedUser.replace('@', '').replace(`:${suffix}`, '');
};
