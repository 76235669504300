import { useGetMatrixRooms } from './useGetMatrixRooms';
import { RoomInformation, useMatrixNameResolver } from './useMatrixNameResolver';
import { Room } from 'matrix-js-sdk';

export type ExtendedRoom = Room & { roomInformation?: RoomInformation | null };
export const useMatrixDmRooms = (searchValue?: string) => {
  const rooms = useGetMatrixRooms();
  const { resolveRoomName } = useMatrixNameResolver();

  const extendedRooms: ExtendedRoom[] | undefined = rooms?.map((room: ExtendedRoom) => {
    const name = room.name;
    room.roomInformation = resolveRoomName(name);
    return room;
  });

  const dmRooms = extendedRooms?.filter((room) => {
    return room.name?.startsWith('dm_');
  });

  if (searchValue && searchValue !== '')
    return dmRooms?.filter((room) => {
      if (room.roomInformation)
        return room.roomInformation.displayName.toLowerCase().includes(searchValue.toLowerCase());
      return true;
    });

  return dmRooms;
};
