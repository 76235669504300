import { STORAGE_KEY } from './constants';

import {
  CHILD_PROFILES_CLAIM,
  ChildProfilesClaim,
  ORGANIZATION_CLAIM,
  OrganizationClaim,
  OTHER_PROFILES_CLAIM,
  OtherProfilesClaim,
  PROFILE_CLAIM,
  ProfileClaim,
  ROLES_CLAIM,
  RolesClaim,
} from '@bp/pim-auth-constants';
import { Capacitor } from '@capacitor/core';
import { capacitorStorage } from '../BpAuthProvider';
import dayjs from 'dayjs';

const getAuthData = async (): Promise<string | null> => {
  const isNative = Capacitor.isNativePlatform();
  return isNative ? await capacitorStorage.getItem(STORAGE_KEY) : sessionStorage.getItem(STORAGE_KEY);
};

const setData = async (data?: string | null) => {
  const isNative = Capacitor.isNativePlatform();
  if (isNative && data) {
    await capacitorStorage.setItem(STORAGE_KEY, data);
  } else if (data) {
    sessionStorage.setItem(STORAGE_KEY, data);
  }
};

const removeData = () => {
  const isNative = Capacitor.isNativePlatform();
  if (isNative) {
    void capacitorStorage.removeItem(STORAGE_KEY);
  } else {
    sessionStorage.removeItem(STORAGE_KEY);
  }
};

export const clearTokens = () => {
  const isNative = Capacitor.isNativePlatform();
  if (isNative) {
    void capacitorStorage.removeItem(STORAGE_KEY);
  } else {
    sessionStorage.removeItem(STORAGE_KEY);
  }
};

export const getClaims = async (): Promise<UserInfoResponse | null> => {
  const data = await getAuthData();
  if (data) {
    return JSON.parse(data).profile;
  }
  return null;
};

export const getTokens = async (): Promise<{
  access_token?: string;
  refresh_token?: string;
  expires_at?: number;
  id_token?: string;
} | null> => {
  const data = await getAuthData();
  if (data) {
    const tokenData = JSON.parse(data);
    const accessToken = tokenData?.access_token;
    const refreshToken = tokenData?.refresh_token;
    let expiresAt = tokenData?.expires_at;
    const idToken = tokenData?.id_token;

    if (expiresAt <= dayjs().unix()) {
      expiresAt = dayjs().add(expiresAt).unix();
      await setData(JSON.stringify({ ...tokenData, expires_at: expiresAt }));
    }

    if (accessToken) {
      return { access_token: accessToken, refresh_token: refreshToken, expires_at: expiresAt, id_token: idToken };
    }
  }
  return null;
};

export type UserInfoResponse = {
  sub: string;
  PROFILE_CLAIM: ProfileClaim;
  OTHER_PROFILES_CLAIM: OtherProfilesClaim;
  CHILD_PROFILES_CLAIM: ChildProfilesClaim;
  ORGANIZATION_CLAIM: OrganizationClaim;
  ROLES_CLAIM: RolesClaim;
};

export async function updateClaims(callback?: (userInfo: UserInfoResponse | undefined) => void) {
  let userInfo: UserInfoResponse | undefined = undefined;
  const tokens = await getTokens();

  if (tokens?.access_token !== null) {
    userInfo = await fetch(import.meta.env.VITE_APP_AUTH_SERVER_URI + '/me', {
      headers: { Authorization: 'Bearer ' + tokens?.access_token },
    })
      .then((r) => r.json() as unknown as UserInfoResponse)
      .catch(() => undefined);

    if (userInfo) {
      const data = await getAuthData();
      const newUserInfo = JSON.parse(data || '{}');
      if (newUserInfo.profile.sub === userInfo.sub) {
        newUserInfo.profile[PROFILE_CLAIM] = userInfo[PROFILE_CLAIM as keyof UserInfoResponse];
        newUserInfo.profile[OTHER_PROFILES_CLAIM] = userInfo[OTHER_PROFILES_CLAIM as keyof UserInfoResponse];
        newUserInfo.profile[CHILD_PROFILES_CLAIM] = userInfo[CHILD_PROFILES_CLAIM as keyof UserInfoResponse];
        newUserInfo.profile[ORGANIZATION_CLAIM] = userInfo[ORGANIZATION_CLAIM as keyof UserInfoResponse];
        newUserInfo.profile[ROLES_CLAIM] = userInfo[ROLES_CLAIM as keyof UserInfoResponse];
      }

      await setData(JSON.stringify(newUserInfo));
    } else {
      removeData();
    }
  }

  callback && callback(userInfo);
  return userInfo;
}
