import { useAuthClaims } from './useAuthClaims';
import { useContext, useMemo, useRef } from 'react';
import { BpPermissionChecker } from '../utils/PermissionChecker';
import { OrganizationConfigContext } from '../context/OrganizationConfigContext';
import { useGroupPermissionsQuery } from '../client/bp-graphql-client-defs';
import { GroupRoles, RolesClaim } from '@bp/pim-auth-constants';

export const usePermissionChecker = () => {
  const { pimAuthClaims } = useAuthClaims();
  const profile = pimAuthClaims.getProfile();
  const roles = pimAuthClaims.getRoles();
  const user = pimAuthClaims.getUser();
  const otherProfiles = pimAuthClaims.getOtherProfiles();
  const childProfiles = pimAuthClaims.getChildProfiles();
  const organization = pimAuthClaims.getOrganization();
  const omniOrganization = pimAuthClaims.getOmniOrganization();

  const [{ data: groupPermissionData }] = useGroupPermissionsQuery({ variables: { profileUuid: profile?.uuid ?? '' } });

  const groupRoles = useMemo(() => {
    const roles: RolesClaim = [];
    groupPermissionData?.groups.forEach((group) => {
      if (group.admins.find((p) => p.uuid === profile?.uuid)) {
        roles.push({ target: { type: 'Group', uuid: group.uuid }, name: GroupRoles.Admin });
      }
      if (group.editors.find((p) => p.uuid === profile?.uuid)) {
        roles.push({ target: { type: 'Group', uuid: group.uuid }, name: GroupRoles.Editor });
      }
      if (group.viewers.find((p) => p.uuid === profile?.uuid)) {
        roles.push({ target: { type: 'Group', uuid: group.uuid }, name: GroupRoles.Viewer });
      }
    });

    return roles;
  }, [groupPermissionData, profile]);

  const permissionChecker = useRef<BpPermissionChecker | null>(null);

  const { allowStudentDirectMessages } = useContext(OrganizationConfigContext);

  if (!profile || !roles || !user || !omniOrganization || !organization) {
    console.warn('Insufficient claims for permission checker. Returning deny-all permission checker');
    permissionChecker.current = null;
  } else {
    permissionChecker.current = new BpPermissionChecker(
      {
        profile: profile,
        organization: organization,
        roles: [...roles, ...groupRoles],
        user: user,
        omniOrganization: omniOrganization,
        otherProfiles: otherProfiles,
        childProfiles: childProfiles,
      },
      allowStudentDirectMessages,
    );
  }

  return permissionChecker.current;
};
