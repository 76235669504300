import { useGetMatrixRooms } from './useGetMatrixRooms';
import { RoomNames } from '../../utils/matrixClient';

export const useGetMatrixRoomByName = () => {
  const rooms = useGetMatrixRooms();
  const getRoom = (uuid: string, type: RoomNames) => {
    return rooms?.find((room) => room.name.includes(type + '_' + uuid));
  };

  return { getRoom: getRoom };
};
