import { MatrixEvent } from 'matrix-js-sdk';
import { useEffect, useState } from 'react';
import { useGetMatrixRoom } from './useGetMatrixRoom';

export const useMatrixRoomMessages = (roomId: string) => {
  const [messages, setMessages] = useState<MatrixEvent[]>([]);
  const room = useGetMatrixRoom(roomId);

  useEffect(() => {
    if (room) {
      setMessages(room.getLiveTimeline().getEvents());
    }
  }, [room]);

  return messages;
};
