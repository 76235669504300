import { backendApi } from '../../utils/backendApi';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthClaims } from '../useAuthClaims';
import { usePermissionChecker } from '../usePermissionChecker';
import { getMatrixRoomId } from '../../utils/matrixRoomId';
import { ProfileRoles } from '@bp/pim-auth-constants';

export const useRedirectToDmMessagePage = (context: 'course' | 'group') => {
  const [loading, setLoading] = useState<string | null>(null);
  const navigate = useNavigate();

  const { pimAuthClaims } = useAuthClaims();
  const permissionChecker = usePermissionChecker();

  const redirectToDmMessagePage = async ({
    groupUuid,
    targetProfileOrganizationUuid,
    targetProfileUuid,
    targetProfileRole,
  }: {
    groupUuid: string;
    targetProfileUuid: string;
    targetProfileOrganizationUuid: string;
    targetProfileRole: ProfileRoles;
  }) => {
    setLoading(targetProfileUuid ?? null);

    const hasPermission =
      context === 'course'
        ? permissionChecker?.canDirectMessageWithinCourse(
            {
              uuid: groupUuid,
              organization: { uuid: pimAuthClaims.getOrganizationUuid() },
            },
            { uuid: targetProfileUuid, organization: { uuid: targetProfileOrganizationUuid }, role: targetProfileRole },
          )
        : permissionChecker?.canDirectMessageWithInGroup(
            {
              uuid: groupUuid,
              organization: { uuid: pimAuthClaims.getOrganizationUuid() },
            },
            { uuid: targetProfileUuid, organization: { uuid: targetProfileOrganizationUuid }, role: targetProfileRole },
          );

    if (targetProfileUuid && hasPermission) {
      const response = await backendApi.createMatrixDm(targetProfileUuid);
      const json = await response.json();
      if (json.success && json.matrixRoomId) {
        const matrixRoomId = await getMatrixRoomId(json.matrixRoomId);
        navigate(`/messages/${matrixRoomId}`);
      }
    }
    setLoading(null);
  };

  return { redirectToDmMessagePage, loading };
};
