import { useEffect, useState } from 'react';
import { backendApi, MatrixTokenType } from '../../utils/backendApi';

export const useGetMatrixToken = () => {
  const [token, setToken] = useState<MatrixTokenType>();

  useEffect(() => {
    backendApi.getMatrixToken().then((token) => {
      if (token) {
        setToken(token);
      }
    });
  }, []);

  return token;
};
