import { useMatrixClient } from './useMatrixClient';
import { useEffect, useState } from 'react';

export const useMatrixAvailable = (): boolean => {
  const client = useMatrixClient();

  const [matrixOnline, setMatrixOnline] = useState<boolean>(false);
  const [matrixLoggedIn, setMatrixLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    setMatrixLoggedIn(client ? client.isLoggedIn() : false);
  }, [client]);

  useEffect(() => {
    setMatrixOnline(client ? client.isLoggedIn() && client.isInitialSyncComplete() : false);
  }, [client, matrixLoggedIn]);

  return matrixOnline;
};
