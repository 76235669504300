import { INavigator, IWindow, NavigateParams } from 'oidc-client-ts';
import { Browser } from '@capacitor/browser';

class CapacitorWindow implements IWindow {
  async navigate(params: NavigateParams) {
    await Browser.open({ url: params.url });
    return { url: params.url };
  }
  async close() {
    await Browser.close();
  }
}

export class CapacitorNavigator implements INavigator {
  callback(url: string, params?: unknown) {
    return Promise.resolve();
  }
  async prepare(params: unknown): Promise<IWindow> {
    return new CapacitorWindow();
  }
}
