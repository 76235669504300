import { Room } from 'matrix-js-sdk';
import { useMatrixRoomMessages } from './useMatrixRoomMessages';
import { AnnouncementsType } from '../../components/AnnouncementsList/AnnouncementsList';
import { useRefreshOnEvent } from './useRefreshOnEvent';
import { lcFirst } from '../../utils/lcFirst';

export enum MatrixNoticeTypes {
  Announcement = 'announcement',
}

export type MatrixAnnouncementContent = {
  type: MatrixNoticeTypes;
  title: string;
  message: string;
};

export const useGetAnnouncements = (room?: Room): AnnouncementsType[] => {
  const messages = useMatrixRoomMessages(room?.roomId ?? '');
  useRefreshOnEvent();

  const announcements: AnnouncementsType[] = [];
  messages.forEach((event) => {
    let content: MatrixAnnouncementContent | null;
    try {
      content = JSON.parse(event.getContent().body);
    } catch {
      content = null;
    }

    if (content && lcFirst(content.type) === MatrixNoticeTypes.Announcement) {
      announcements.push({
        date: event.getDate() ?? new Date(),
        id: event.getId() ?? '',
        isUnread: false,
        title: content.title ?? '',
        message: content.message ?? '',
        matrixEvent: event,
      });
    }
  });
  return announcements;
};
