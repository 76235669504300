import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { AsyncStorage } from 'oidc-client-ts';

export class CapacitorStorage implements AsyncStorage {
  readonly length = SecureStoragePlugin.keys().then((keys) => keys.value.length);

  async clear() {
    await SecureStoragePlugin.clear();
  }

  getItem(key: string) {
    return SecureStoragePlugin.get({ key: key }).then((item) => {
      return item.value;
    });
  }

  async key(index: number) {
    const keys = await SecureStoragePlugin.keys();
    return keys.value.length >= index ? keys.value[index] : null;
  }

  async removeItem(key: string) {
    await SecureStoragePlugin.remove({ key: key });
  }

  async setItem(key: string, value: string) {
    await SecureStoragePlugin.set({ key: key, value: value });
  }
}
