import { useMemo } from 'react';

export const cacheTags = {
  GROUP: 'Group',
  GROUP_LIST: 'GroupList',
  COURSE: 'Course',
  COURSE_LIST: 'CourseList',
  SUBJECT: 'Subject',
  SCHOOL_YEAR: 'Schoolyear',
  TEACHING_UNIT: 'TeachinUnit',
  PROFILE: 'Profile',
  PARENTS: 'Parents',
  SUBMISSION: 'Submission',
  ASSIGNMENT: 'Assignment',
  WORKMATERIAL: 'WorkMaterial',
  EVENT: 'EVENT',
  COLLABORATING: 'Collaborating',
  FILE_ENTRY: 'FileEntry',
  PRODUCTS: 'LicensedProducts',
  TOPICS: 'Topics',
  SETTINGS: 'Settings',
  ABSENCE_REASONS: 'AbsenceReason',
  ORGANIZATION: 'Organization',
};

type Keys = keyof typeof cacheTags;

function useMemoizedCacheTag(tag: Keys | Keys[]) {
  return useMemo(() => {
    return {
      additionalTypenames: [...tag].map((cacheTag) => cacheTags[cacheTag as keyof typeof cacheTags]),
    };
  }, [tag]);
}

function getCacheTag(tag: keyof typeof cacheTags) {
  return {
    additionalTypenames: [cacheTags[tag]],
  };
}

export { useMemoizedCacheTag, getCacheTag };
