import { TableColumns } from '@bp/ui-components';

export function isNotEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

export type ColumnType<T> = Pick<TableColumns<T>, 'type'>['type'];

export function isPlainOldObject(possibleObject?: unknown): possibleObject is Record<string, unknown | undefined> {
  return !!possibleObject && typeof possibleObject === 'object' && !Array.isArray(possibleObject);
}

export function isString(s: unknown): s is string {
  return typeof s === 'string';
}

export function isArray(possibleArray: unknown): possibleArray is unknown[] {
  return Array.isArray(possibleArray);
}

export function isStringArray(possibleStringArray: unknown): possibleStringArray is string[] {
  if (!isArray(possibleStringArray)) {
    return false;
  }

  for (const element of possibleStringArray) {
    if (!isString(element)) {
      return false;
    }
  }

  return true;
}
