export const STORAGE_KEY =
  'oidc.user:' + import.meta.env.VITE_APP_AUTH_SERVER_URI + ':' + import.meta.env.VITE_APP_AUTH_CLIENT_ID;
//export const STORAGE_KEY = 'test_'

export const UNAUTHORIZED_QUERY = 'UNAUTHENTICATED';
export const TOASTOPTIONS = {
  position: 'bottom-center',
  style: {
    backgroundColor: '#3A3A41',
    color: '#fff',
  },
};
