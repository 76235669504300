import { useEffect, useState } from 'react';
import { MatrixClient } from 'matrix-js-sdk';
import { BpMatrixClient } from '../../utils/matrixClient';

export const useMatrixClient = () => {
  const [client, setClient] = useState<MatrixClient | null>(null);

  useEffect(() => {
    BpMatrixClient.instance().then((client) => {
      if (client) {
        setClient(client);
      }
    });
  }, []);
  return client;
};
