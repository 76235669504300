import { EventType, MsgType } from 'matrix-js-sdk';
import { useGetMatrixRoom } from './useGetMatrixRoom';
import { useMatrixClient } from './useMatrixClient';
import { useMatrixRoomMessages } from './useMatrixRoomMessages';
import { ConversationType } from '../../utils/matrixClient';
import { useMemoizedCacheTag } from '../useMemoizedCacheTag';
import { getFullyQualifiedUser, getUuidFromFullyQualifiedUser } from '../../utils/matrixRoomId';
import { useAuthClaims } from '../useAuthClaims';
import { MatrixEvent } from 'matrix-js-sdk/lib/models/event';
import { useGetMatrixToken } from './useGetMatrixToken';
import { useBpProfileQuery } from '../../client/bp-graphql-client-defs';

export const useMatrixRoomMessagesAsConversation = (roomId: string): ConversationType => {
  const room = useGetMatrixRoom(roomId);
  const matrixClient = useMatrixClient();
  const events = useMatrixRoomMessages(roomId);
  const context = useMemoizedCacheTag('PROFILE');
  const { pimAuthClaims } = useAuthClaims();
  const token = useGetMatrixToken();

  const [{ data: profileData }] = useBpProfileQuery({ context });

  const matrixUser = token ? getFullyQualifiedUser(pimAuthClaims.getProfile().uuid, token?.home_server) : '';
  const conversation: ConversationType = [];

  let last: MatrixEvent | null = null;
  events?.forEach((matrixEvent, index) => {
    if (matrixEvent.getType() === EventType.RoomMessage) {
      if (matrixEvent.getRoomId() === room?.roomId && matrixEvent.getContent().msgtype === MsgType.Text) {
        const profile = profileData?.profiles.find((profile) => {
          if (token && matrixEvent.sender) {
            return profile.uuid === getUuidFromFullyQualifiedUser(matrixEvent.sender.userId ?? '', token.home_server);
          }
          return null;
        });
        if (
          events &&
          events[index - 1] &&
          conversation[conversation.length - 1] &&
          conversation[conversation.length - 1].messages &&
          matrixEvent.sender?.userId === last?.sender?.userId
        ) {
          conversation[conversation.length - 1].messages.push({
            type: '',
            content: matrixEvent.getContent().body,
            date: matrixEvent.getDate() ?? new Date(),
            matrixEvent: matrixEvent,
          });
        } else {
          conversation.push({
            messages: [
              {
                type: '',
                content: matrixEvent.getContent().body,
                date: matrixEvent.getDate() ?? new Date(),
                matrixEvent: matrixEvent,
              },
            ],
            isIncoming: matrixEvent.sender?.userId !== matrixUser,
            color: matrixEvent.getSender() === matrixClient?.getUserId() ? 'red' : 'blue',
            initials: profile && profile.displayNameShort ? `${profile.displayNameShort}` : '',
            id: matrixEvent.getId() ?? '',
          });
        }
        last = matrixEvent;
      }
    }
  });

  return conversation;
};
