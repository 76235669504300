import { NotificationReasons, NotificationType } from '../../../../utils/matrixClient';
import { t } from 'i18next';
import { GroupType } from '../../../../client/bp-graphql-client-defs';
import { BpRoomType } from '../../../../hooks/matrix/notifications/useNotifications';

export function mapNotification(notification: NotificationType) {
  let content = '';
  let targetUrl = '#';

  // can be removed when typing ist consistent
  const type = notification.type?.charAt(0).toLowerCase() + notification.type?.slice(1);

  switch (type) {
    case NotificationReasons.EventStarted:
      content = `${t('notifications.eventStarted')} <strong>${notification.groupName}</strong>: ${notification.subjectName}`;
      if (notification.groupType === BpRoomType.COURSE) targetUrl = `/courses/${notification.groupUuid}/`;
      if (notification.groupType === BpRoomType.GROUP) targetUrl = `/groups/${notification.groupUuid}/`;
      break;

    case NotificationReasons.EventEnded:
      content = `${t('notifications.eventEnded')} <strong>${notification.groupName}</strong>: ${notification.subjectName}`;
      if (notification.groupType === BpRoomType.COURSE) targetUrl = `/courses/${notification.groupUuid}/`;
      if (notification.groupType === BpRoomType.GROUP) targetUrl = `/groups/${notification.groupUuid}/`;
      break;

    case NotificationReasons.CourseEventCreated:
      content = `${t('notifications.newCourseEvent')} <strong>${notification.title}</strong>: ${notification.subjectName}`;
      targetUrl = `/courses/${notification.groupUuid}/appointments`;
      break;

    case NotificationReasons.RecurringCourseEventCreated:
      content = `${t('notifications.newRecurringCourseEvent')} <strong>${notification.title}</strong>: ${notification.subjectName}`;
      targetUrl = `/groups/${notification.groupUuid}/appointments`;
      break;

    case NotificationReasons.GroupEventCreated:
      content = `${t('notifications.newGroupEvent')} <strong>${notification.title}</strong>: ${notification.subjectName}`;
      targetUrl = `/groups/${notification.groupUuid}/appointments`;
      break;

    case NotificationReasons.RecurringGroupEventCreated:
      content = `${t('notifications.newRecurringGroupEvent')} <strong>${notification.title}</strong>: ${notification.subjectName}`;
      targetUrl = `/groups/${notification.groupUuid}/appointments`;
      break;

    case NotificationReasons.OrganizationEventCreated:
      content = `${t('notifications.newOrganizationEvent')} <strong>${notification.title}</strong>: ${notification.subjectName}`;
      targetUrl = `/`;
      break;

    case NotificationReasons.NewGroupMembership:
      if (notification.isCommunity) {
        // collaboration group
        content = `${t('notifications.addedToCollabGroup')} <strong>${notification.subjectName}</strong>`;
      } else {
        // normal group
        content = `${t('notifications.addedToGroup')} <strong>${notification.subjectName}</strong>`;
      }
      if (notification.groupUuid) targetUrl = `groups/${notification.groupUuid}`;
      break;

    case NotificationReasons.RemovedFromGroup:
      if (notification.isCommunity) {
        // collaboration group
        content = `${t('notifications.removedFromCollabGroup')} <strong>${notification.subjectName}</strong>`;
      } else {
        // normal group
        content = `${t('notifications.removedFromGroup')} <strong>${notification.subjectName}</strong>`;
      }
      break;

    case NotificationReasons.RemovedFromCourse:
      content = `${t('notifications.removedFromCourse')} <strong>${notification.subjectName}</strong>`;
      break;

    case NotificationReasons.NewGroupAnnouncement:
      content = `${t('notifications.announcementInGroup')} <strong>${notification.subjectName}</strong>: ${
        notification.title
      }`;
      if (notification.groupUuid) targetUrl = `/groups/${notification.groupUuid}`;
      break;

    case NotificationReasons.NewOrganizationAnnouncement:
      // other organization
      if (notification.organizationUuid !== notification.subjectUuid) {
        content = `${t('notifications.announcementInOrganization')}  <strong>${notification.subjectName}</strong>: ${
          notification.title
        } - ${t('notifications.changeOrganization')}`;
      }

      if (notification.organizationUuid === notification.subjectUuid) {
        content = `${t('notifications.announcementInOrganization')}  <strong>${notification.subjectName}</strong>: ${
          notification.title
        }`;

        targetUrl = `/`;
      }

      break;

    case NotificationReasons.NewCourseAnnouncement:
      content = `${t('notifications.announcementInCourse')} <strong>${notification.subjectName}</strong>: ${
        notification.title
      }`;
      targetUrl = `/courses/${notification.groupUuid}`;
      break;

    case NotificationReasons.AssignmentCreated:
      content = t('notifications.newAssignment', { name: notification.subjectName });
      targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.subjectUuid}`;
      break;

    case NotificationReasons.AssignmentUpdated:
      content = t('notifications.assignmentUpdated', { name: notification.subjectName });
      targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.subjectUuid}`;
      break;

    case NotificationReasons.AssignmentDeleted:
      content = t('notifications.assignmentDeleted', { name: notification.subjectName });
      targetUrl = `/courses/${notification.groupUuid}/assignments`;
      break;

    case NotificationReasons.Comment:
      content = `${t('groups.newMessageHint')} <strong>${notification.subjectName}</strong>`;
      targetUrl = `/messages`;
      break;

    case NotificationReasons.SubmissionDePublished:
      content = `${t('notifications.publicSubmissionRejected')} <strong>${notification.subjectName}</strong>`;
      targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.assignmentUuid}/submissions/${notification.subjectUuid}`;
      break;

    case NotificationReasons.RequestToFeedback:
      content = `${t('notifications.feedbackRequested')} <strong>${notification.subjectName}</strong>`;
      targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.assignmentUuid}/submissions/${notification.subjectUuid}`;
      break;
    case NotificationReasons.RequestToPublish:
      content = `${t('notifications.submissionPublicationRequested')} <strong>${notification.subjectName}</strong>`;
      targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.assignmentUuid}/submissions/${notification.subjectUuid}`;
      break;

    case NotificationReasons.FeedbackRejected:
      content = `${t('notifications.feedbackRejected')} <strong>${notification.subjectName}</strong>`;
      targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.assignmentUuid}/submissions/${notification.subjectUuid}`;
      break;

    case NotificationReasons.SubmissionFeedback:
      content = `${t('notifications.feedbackGranted')} <strong>${notification.subjectName}</strong>`;
      targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.assignmentUuid}/submissions/${notification.subjectUuid}`;
      break;

    case NotificationReasons.SubmissionUpdated:
      content = `${t('notifications.submissionEdited')} <strong>${notification.subjectName}</strong>`;
      targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.assignmentUuid}/submissions/${notification.subjectUuid}`;
      break;

    case NotificationReasons.SubmissionMessage:
      content = `${t('notifications.messageInCourse', { name: notification.subjectName })}`;
      targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.assignmentUuid}/submissions/${notification.subjectUuid}`;
      break;

    case NotificationReasons.NewCourseMaterial:
      content = `${t('notifications.newCourseMaterial')} <strong>${notification.groupName} - ${notification.subjectName}</strong>`;
      targetUrl = `/courses/${notification.groupUuid}/workmaterials`;

      break;

    case NotificationReasons.CourseMaterialUpdated:
      content = `${t('notifications.materialUpdated')} <strong>${notification.groupName} - ${notification.subjectName}</strong>`;
      targetUrl = `/courses/${notification.groupUuid}/workmaterials/${notification.subjectUuid}`;

      break;
    case NotificationReasons.GroupMaterialUpdated:
      content = `${t('notifications.materialUpdated')} <strong>${notification.groupName} - ${notification.subjectName}</strong>`;
      targetUrl = `/groups/${notification.groupUuid}/workmaterials/${notification.subjectUuid}`;
      break;

    case NotificationReasons.NewGroupMaterial:
      content = `${t('notifications.newMaterial')} <strong>${notification.groupName} - ${notification.subjectName}</strong>`;
      targetUrl = `/groups/${notification.groupUuid}/workmaterials/${notification.subjectUuid}`;
      break;

    case NotificationReasons.NewCourseMembership:
      content = `${t('notifications.newCourse')} <strong>${notification.subjectName}</strong>`;
      targetUrl = `/courses/${notification.groupUuid}`;
      break;

    case NotificationReasons.SubmissionNew:
      if (
        notification.subjectName !== undefined &&
        notification.assignmentUuid !== undefined &&
        notification.subjectUuid !== undefined &&
        notification.groupUuid !== undefined
      ) {
        content = `${t('notifications.newSubmission')} <strong>${notification.subjectName}</strong>`;
        targetUrl = `/courses/${notification.groupUuid}/assignments/${notification.assignmentUuid}/submissions/${notification.subjectUuid}`;
      }

      break;

    case NotificationReasons.MaterialDeleted:
      content = `${t('notifications.materialDeleted')}: <strong>${notification.groupName} - ${notification.subjectName}</strong>`;
      if (notification.groupType === GroupType.Course) targetUrl = `/courses/${notification.groupUuid}`;
      if (notification.groupType === GroupType.Group) targetUrl = `/groups/${notification.groupUuid}`;
      break;

    case NotificationReasons.Announcement:
      content = `${t('notifications.announcement')}: ${notification.subjectName}`;
      break;

    default:
      break;
  }

  return { content, targetUrl };
}
