import { useMatrixClient } from './useMatrixClient';
import { useEffect, useState } from 'react';
import { Room } from 'matrix-js-sdk';
import { useMatrixAvailable } from './useMatrixAvailable';

export const useGetMatrixRooms = () => {
  const [rooms, setRooms] = useState<Room[] | null>(null);
  const online = useMatrixAvailable();
  const matrixClient = useMatrixClient();
  useEffect(() => {
    const matrixRooms = matrixClient?.getVisibleRooms();
    if (matrixRooms) {
      setRooms(matrixRooms.sort((a, b) => b.getLastActiveTimestamp() - a.getLastActiveTimestamp()));
    }
  }, [matrixClient, online]);
  return rooms;
};
