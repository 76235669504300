import i18next from 'i18next';
import { AdminRoles, ProfileRoles } from '@bp/pim-auth-constants';

export const organizationRolesAsString = (roles: Array<string | undefined | null>) => {
  if (roles.includes(ProfileRoles.Teacher)) {
    return i18next.t('rolesInOrganization.teacher', { count: 1 });
  }
  if (roles.includes(ProfileRoles.Parent)) {
    return i18next.t('rolesInOrganization.parent', { count: 1 });
  }
  if (roles.includes(ProfileRoles.Other)) {
    return i18next.t('rolesInOrganization.other');
  }
  if (roles.includes(ProfileRoles.Student)) {
    return i18next.t('rolesInOrganization.student', { count: 1 });
  }

  if (roles.includes(AdminRoles.OrganizationAdmin)) {
    return i18next.t('rolesInOrganization.teacher', { count: 1 }); // do we show admins as admin ?
  }

  return i18next.t('rolesInOrganization.student', { count: 1 });
};
